<template>
<div class="job-card-content">
    <b-button
    variant="primary"
    class="float-right ml-1 mt-1"
    @click="printClick"
    >
    Print
    </b-button>
    <b-button
    class="float-right mt-1"
    @click="downloadPdf"
    >
    Download
    </b-button>
    <div ref="jobReportContent">
        <span class="brand-logo">
            <b-img
                src="../../assets/images/logo/logo.svg"
                alt="logo"
                class="m-1 brand-logo-img"
            />
        </span>
        <div class="pgrbrk">
            <b-card-group class="mt-1">
                <b-card>
                    <b-card-header>
                            <h2 style="font-weight: 500;">{{ branch }}</h2>
                    </b-card-header>
                    <b-card-text>
                    <b-row>
                        <b-col>
                        <p><span>Job Card:</span> {{ fsdNumber }}</p>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                        <p><span>Date & Time:</span> {{ dateTime }}</p>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                        <p><span>Technician:</span> {{ technician }}</p>
                        </b-col>
                    </b-row>
                    </b-card-text>
                </b-card>
                <b-card>
                    <b-card-text>
                    <b-row>
                        <b-col>
                        <p><span>Registration Number:</span> {{ registrationNumber }}</p>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                        <p><span>Order Number:</span> {{ orderNumber }}</p>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                        <p><span>Odometer Reading:</span> {{ odometer }}</p>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                        <p><span>Notes:</span> {{ notes }}</p>
                        </b-col>
                    </b-row>
                    </b-card-text>
                </b-card>
            </b-card-group>
        </div>
        <div
        v-if="gpsLocation.length > 1"
        class="pgrbrk"
        >
            <h2
            style="padding-top: 20px;"
            >
            Location
            </h2>
            <b-card-group>
                <b-card>
                    <b-row
                    class="mb-2"
                    >
                        <b-col ref="googleMapImg">
                        <img
                        :src="`https://maps.googleapis.com/maps/api/staticmap?center=${gpsLocation}&zoom=12
                        &size=${mapImg}x150
                        &scale=2&markers=color:red|${gpsLocation}
                        &key=AIzaSyBXcpLBxfEnWONKrLQBNsA13DWLlQUMl_E`"
                        class="mapImgSize"
                        >
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                        <p><span>GPS Location:</span> {{ gpsLocation }}</p>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                        <p><span>Approx. Address:</span> {{ location }}</p>
                        </b-col>
                    </b-row>
                </b-card>
                <!-- <b-card >
                    <b-card-text>
                    <b-row>
                        <b-col>
                        <p><span>GPS Location:</span> {{ gpsLocation }}</p>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                        <p><span>Approx. Address:</span> {{ location }}</p>
                        </b-col>
                    </b-row>
                    </b-card-text>
                </b-card> -->
            </b-card-group>
        </div>
        <div class="pgrbrk">
            <h2
            style="padding-top: 20px;"
            >
            Vehicle
            </h2>
            <b-card-group>
                <b-card>
                    <b-card-text>
                        <b-row>
                            <b-col>
                            <p><span>Equipment Class:</span> {{ equipmentClass }}</p>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                            <p><span>Vehicle Layout:</span> {{ vehicleLayout }}</p>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                            <p><span>Customer:</span> {{ customer }}</p>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                            <p><span>Fleet #:</span> {{ fleetNumber }}</p>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
            </b-card-group>
        </div>
        <div class="pgrbrk">
            <h2
            style="padding-top: 20px;"
            >
            Wheel Positions Touched
            </h2>
            <b-card-group>
                <b-card>
                    <div
                        v-if="wheelLayout.length>0"
                        class="d-flex justify-content-center"
                    >
                        <div style="max-width: 40rem;">
                        <div
                            v-for="(row,rowKey) in wheelLayout"
                            :key="`row-${rowKey}`"
                            :class="{'d-flex mb-1': true, 'justify-content-between': true, }"
                        >
                            <a
                                v-for="(wheel, wheelKey) in row"
                                :key="`wheel-${wheelKey}`"
                                :class="{
                                    'btn mx-2 min-width-6' : true,
                                    'btn-outline-primary': wheel.selected,
                                    'btn-outline-warning': !wheel.selected,
                                    'saved': wheel.saved,
                                }"
                            >
                                {{ wheel.name }}
                            </a>
                        </div>
                        </div>
                    </div>
                </b-card>
            </b-card-group>
        </div>
        <div
        v-if="products.length !== 0"
        class="pgrbrk"
        >
            <h2
            style="padding-top: 20px;"
            >
            Line Item Summary
            </h2>
            <b-card-group>
                <b-card>
                    <b-card-text>
                        <b-table
                            show-empty
                            empty-text="Sorry! No Data Found"
                            :items="products"
                            responsive="sm"
                            :busy="isLoading"
                        >
                            <template #table-busy>
                                <div class="text-center text-primary my-2">
                                    <b-spinner class="align-middle" />
                                    <strong>&nbsp; Loading...</strong>
                                </div>
                            </template>
                            <template>
                                <div
                                v-if="products.length === 0"
                                class="row"
                                >
                                    <div class="col col-md-12">
                                        <p>No Data Found</p>
                                    </div>
                                </div>
                            </template>
                        </b-table>
                    </b-card-text>
                </b-card>
            </b-card-group>
        </div>
        <!-- tread depth -->
        <div
        v-if="treadDepths.length !== 0"
        class="pgrbrk"
        >
            <h2
            style="padding-top: 20px;"
            >
            Tread Depths
            </h2>
            <b-card-group>
                <b-card>
                    <b-card-text>
                        <b-table
                            show-empty
                            empty-text="Sorry! No Data Found"
                            :items="treadDepths"
                            responsive="sm"
                            :busy="isLoading"
                        >
                            <template #table-busy>
                                <div class="text-center text-primary my-2">
                                    <b-spinner class="align-middle" />
                                    <strong>&nbsp; Loading...</strong>
                                </div>
                            </template>
                            <template>
                                <div
                                v-if="treadDepths.length === 0"
                                class="row"
                                >
                                    <div class="col col-md-12">
                                        <p>No Data Found</p>
                                    </div>
                                </div>
                            </template>
                        </b-table>
                    </b-card-text>
                </b-card>
            </b-card-group>
        </div>
        <div class="pgrbrk">
            <h2
            style="padding-top: 20px;"
            >
            Photos
            </h2>
            <b-card-group>
                <b-card>
                    <b-card-header>
                        <b-card-title>
                            Vehicle Photos
                        </b-card-title>
                    </b-card-header>
                        <b-card-body>
                        <b-row>
                            <b-col
                            v-for="(val, index) of media"
                            :key="index"
                            class="p-1"
                            >
                            <img
                            :src="`data:image;base64,${val}`"
                            width="150"
                            height="150"
                            >
                            </b-col>
                        </b-row>
                        </b-card-body>
                </b-card>
            </b-card-group>
        </div>
        <div class="pgrbrk">
            <b-card-group>
                <b-card>
                    <b-card-header>
                        <b-card-title>
                        Wheel Position Photos:
                        </b-card-title>
                    </b-card-header>
                        <b-card-text>
                        <b-row
                        v-for="itm of wheelPositionMedia"
                        :key="itm.id"
                        >
                        <h5 class="p-2">
                        {{ itm.name }}
                        </h5>
                            <b-col
                            v-for="(posImg, index) of itm.img"
                            :key="index"
                            class="p-1"
                            >
                            <img
                            :src="`data:image;base64,${posImg}`"
                            width="150"
                            height="150"
                            >
                            </b-col>
                        </b-row>
                        </b-card-text>
                </b-card>
            </b-card-group>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import {
    BCardGroup,
    BCard,
    BCardHeader,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BTable,
    BButton,
    BImg,
    BCardTitle,
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import html2pdf from 'html2pdf.js';
export default {
components: {
    BCardGroup,
    BCard,
    BCardHeader,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BTable,
    BButton,
    BImg,
    BCardTitle,
},
data() {
    return {
        id: 0,
        orderNumber: '',
        registrationNumber: '',
        customer: '',
        dateTime: '',
        equipmentClass: '',
        fleetNumber: '',
        fsdNumber: '',
        gpsLocation: '',
        location: '',
        media: [],
        products: [],
        treadDepths: [],
        technician: '',
        vehicleLayout: '',
        wheelLayout: [],
        wheelPositionMedia: [],
        isLoading: false,
        mapImg: 600,
        branch: '',
        odometer: '',
        notes: '',
    };
},
created() {
    this.id = this.$route.params.id;
    // this.mapImg = Math.floor(window.screen.availWidth / 2.8)
    // console.log('env ', process.env.VUE_APP_API_URL)
    this.getFetchData();
},
mounted() {
    if (this.$refs.googleMapImg) {
        this.mapImg = Math.floor(this.$refs.googleMapImg.clientWidth / 2 - 15)
    }
},
methods: {
    // toDataURL(url, callback) {
    //     const xhr = new XMLHttpRequest();
    //     xhr.onload = function() {
    //         const reader = new FileReader();
    //         reader.onloadend = function() {
    //         callback(reader.result);
    //         }
    //         reader.readAsDataURL(xhr.response);
    //     };
    //     xhr.open('GET', url);
    //     xhr.responseType = 'blob';
    //     xhr.send();
    // },
    getFetchData() {
        this.$helper.showLoading();
        this.$axios.post('/jobs/get_job_card_details', {
            job_id: this.id,
            location: this.gpsLocation,
            })
            .then(res => {
                this.$helper.hideLoading();
                if (res.data) {
                    this.odometer = res.data.odometer_reading
                    this.notes = res.data.note
                    this.branch = res.data.branch_name
                    this.orderNumber = res.data.Order_number
                    this.registrationNumber = res.data.Registration_number
                    this.customer = res.data.customer
                    this.dateTime = res.data.date_time
                    this.equipmentClass = res.data.equipment_class
                    this.fleetNumber = res.data.fleet_number
                    this.fsdNumber = res.data.fsd_number
                    this.gpsLocation = res.data.gps_location
                    this.media = res.data.media
                    this.products = res.data.products
                    this.treadDepths = res.data.tread_depths
                    this.technician = res.data.technician
                    this.vehicleLayout = res.data.vehicle_layout
                    this.wheelLayout = res.data.wheel_layout
                    this.wheelLayout.forEach(row => {
                        row.forEach(wheel => {
                            // eslint-disable-next-line no-param-reassign
                            wheel.saved = wheel.selected === 1;
                        })
                    })
                    this.wheelLayout.forEach(row => {
                        row.forEach(pos => {
                            const checkImg = res.data.wheel_position_media.filter(itm => itm.id === pos.id).map(posimg => posimg.media)
                            if (checkImg.length > 0) {
                                this.wheelPositionMedia.push({
                                    id: pos.id,
                                    name: pos.name,
                                    img: checkImg,
                                })
                            }
                        })
                    })
                    this.getAddress(this.gpsLocation)
                }
                console.log(this.wheelPositionMedia)
            })
            .catch(error => {
                this.$helper.hideLoading();
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Oops! Something went wrong',
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                    },
                });
                console.error(error);
            });
    },
    async getAddress(gpsLocation) {
        try {
            const { data } = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${gpsLocation}&key=AIzaSyBXcpLBxfEnWONKrLQBNsA13DWLlQUMl_E`);
            if (data.results[0].formatted_address.trim().length > 0) {
                this.location = data.results[0].formatted_address;
            }
        } catch (error) {
            console.log(error.message);
        }
    },
    downloadPdf() {
        const element = this.$refs.jobReportContent.innerHTML
        const opt = {
            margin: 0.5,
            filename: 'jobReport.pdf',
            // image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2, useCORS: true },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
            };
        html2pdf().from(element).set(opt).save()
    },
    printClick() {
        window.print()
    },
},
}
</script>
<style>
    img.mapImgSize {
        width: 100%
    }
    @media print {
        .card-group {page-break-inside: avoid;}
    }
    .pgrbrk {
        page-break-inside: avoid;
    }
    .job-proper-display .content.app-content {
        padding-top: 2rem !important;
    }
    .navbar-floating .header-navbar-shadow {
        background: none !important;
    }
    .min-width-5 {
        min-width: 5rem;
    }
    .min-width-6 {
        min-width: 6rem;
    }
    .saved::after {
        content: "\2713";
        vertical-align: middle;
        color: var(--primary);
        margin-left: 0.2rem;
    }
    .selected.saved::after {
        color: white !important;
    }
    .selected {
        background-color: #27a746 !important;
        color: white !important;
        border: none !important;
    }
    .text-color-primary{
        color: #27a746 !important;
    }
    .d-flex.justify-content-center {
        overflow: auto;
    }
    .btn-outline-warning {
        border: 2px solid #808080 !important;
    }
    /* .btn-outline-warning:hover {
        color: #4c4d50 !important;
    } */
    .btn-outline-primary {
        border: 2px solid #27a746 !important;
        color: #27a746;
    }
    /*.btn-outline-primary:hover {
        color: var(--green) !important;
    }*/
    .list-group {
        max-height: 150px;
        overflow-y: scroll;
    }
    .filter-style .card-text {
        padding: 1rem 1.5rem 0rem 1.5rem!important;
    }
    .job-card-content {
        padding: calc(1rem + 0.45rem + 1.3rem) 4rem 2rem;
    }
    span {
        font-weight: bold;
        padding: 2px;
    }
    p {
        margin-bottom: 2px !important;
    }
    .card {
        border: 1px solid black !important;
        margin-right: 10px !important;
        border-radius: 5px !important;
    }
    .card-body {
        padding: 1rem !important;
    }
    .card-header {
        padding: 0 0 10px 0 !important;
    }
    .brand-logo-img {
        max-width: 100%;
        max-height: 50px;
    }
    @media (max-width: 660px) {
        .min-width-5 {
            min-width: 2rem;
        }
        /* .min-width-6 {
            min-width: 3rem;
        } */
        .mx-2 {
            margin-left: 2px !important;
            margin-right: 2px !important;
        }
        .d-flex.justify-content-center .d-flex.mb-1.justify-content-between {
            margin-bottom: 4px !important;
        }
        textarea#other-service-comments {
            height: 65px !important;
        }
        .card .card-header {
            padding-left: 0;
            padding-right: 0;
        }
        .card .card .card-body {
            padding: 0px;
        }
        .card .card .ml-1 {
            margin-left: 0rem !important;
        }
        .card .card .mt-2 {
            margin-top: 0.5rem !important;
        }
        .card .card {
            margin-bottom: 0rem;
        }
        .brand-logo-img {
            max-height: 40px;
        }
        /* .card .card img.card-img-top {
            max-height: 225px;
            width: auto;
        } */
    }
</style>
